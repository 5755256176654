import React from "react"
import { Link } from "react-router-dom"

const Home: React.FC = () => {
	return (
		<>
			<div className="bg-[url('/src/images/home.jpg')] bg-top bg-cover py-16 px-4">
				<div className="font-Bree text-white text-[39px] leading-[39px] md:text-[62px] md:leading-[62px] text-center pb-16">
					Der digitale Adventskalendar
				</div>
				<ul className="kalender grid grid-cols-4 md:grid-cols-8 gap-4 max-w-[1300px] mx-auto md:pb-20">
					<li className="tuerchen">
						<div className="tuerchenZahl">23</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">11</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">7</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">1</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">21</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">13</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">17</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">2</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">4</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">10</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">15</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">12</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">5</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">14</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">18</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">20</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">19</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">24</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">22</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">6</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">9</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">3</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">8</div>
					</li>
					<li className="tuerchen">
						<div className="tuerchenZahl">16</div>
					</li>
				</ul>
			</div>
			<Link
				to="/lose"
				className="fixed md:relative bottom-0 md:bottom-auto md:block w-full md:max-w-[784px] bg-red p-8 text-[32px] md:text-[68px] leading-[32px] md:leading-[68px] font-Bree text-center mx-auto text-white md:rounded-2xl md:-translate-y-1/2 -mb-1/2"
			>
				Jetzt Kläppchen öffnen und gewinnen
			</Link>
		</>
	)
}
export default Home
