import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import Header from "./components/Header"
import Home from "./nav/Home"
import Imprint from "./nav/Imprint"
import Store from "./store/store"
import Lose from "./nav/Lose"

const App = () => {
	return (
		<BrowserRouter>
			<Store>
				<Header />
				<main className="overflow-x-hidden">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/lose" element={<Lose />} />
						<Route path="/impressum" element={<Imprint />} />
					</Routes>
				</main>
				<Footer />
			</Store>
		</BrowserRouter>
	)
}

export default App
