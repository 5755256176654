import React from "react"

const Imprint: React.FC = () => {
	return (
		<div className="container max-w-[970px] mx-auto px-4 mb-16 " id="anchor-so-gehts">
			<h1 className="tracking-[-0.04em] pt-0 mt-0 text-[40px] md:text-[56px] leading-[48px] md:leading-[67px] font-bold font-Manrope   mb-16">
				IMPRESSUM
			</h1>
			<p>
				<strong>Impressum nach § 5 Telemediengesetz (TMG)</strong>
				<br />
				<br />
				Betreiber der Webseite:
				<br />
				<br />
				<strong>Arvato Supply Chain Solutions SE</strong>
				<br />
				Reinhard-Mohn-Straße 310
				<br />
				33333 Gütersloh
				<br />
				<br />
				Telefon +49 5241 90-90174
				<br />
				E-Mail: info@arvato-supply-chain.com
				<br />
				Internet: arvato-supply-chain.com
				<br />
				<br />
				<strong>
					Umsatzsteuer-Identifikationsnummer:
					<br />
				</strong>
				<br />
				Arvato Supply Chain Solutions SE
				<br />
				DE 319 051 807
				<br />
				Amtsgericht Gütersloh HRB 11370
				<br />
				<br />
				<strong>Geschäftsführung:</strong>
				<br />
				Andreas Barth, Julia Börs, Frank Schirrmeister, Boris Scholz, Dr. Thorsten Winkelmann
				<br />
				<br />
				<strong>Stand: Juli 2022</strong>
			</p>
		</div>
	)
}
export default Imprint
