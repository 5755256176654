import React from "react"

const Lose: React.FC = () => {
	return (
		<>
			<img
				src={process.env.PUBLIC_URL + "/images/resultHeader.jpg"}
				alt=""
				className="w-full max-w-7xl mx-auto hidden md:block"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/resultHeaderMobile.jpg"}
				alt=""
				className="w-full max-w-7xl mx-auto md:hidden"
			/>

			<div className="max-w-[784px] bg-red p-8 text-[32px] md:text-[68px] leading-[32px] md:leading-[68px] font-Bree text-center mx-auto text-white md:rounded-2xl md:-translate-y-1/2 -mb-1/2">
				Leider hast Du heute nicht gewonnen.
			</div>
			<div className="px-4 text-center font-Bree text-[18px] leading-[30px] md:text-[32px] md:leading-[51px] max-w-[700px] mx-auto mt-12 md:-mt-16">
				Tut uns leid. Heute war das Weihnachtsglück nicht auf Deiner Seite. Probiere es morgen gerne wieder.
			</div>
			<div className="text-center my-12 md:my-[80px]">
				<a
					href="/"
					className="inline-block bg-red text-white rounded-[33px] text-[16px] md:text-[26px] leading-[20px] md:leading-[36px] font-Bree px-6 py-3"
				>
					Zur Startseite
				</a>
			</div>
		</>
	)
}
export default Lose
