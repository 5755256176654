function Footer() {
	type TFooterLink = {
		text: string
		link: string
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
		},
	]

	return (
		<footer>
			<section className="bg-footerBlue">
				<div className="p-8 max-w-[1000px] mx-auto">
					<div className="grid md:grid-cols-[1fr_70px_1fr] gap-4 items-center">
						<div className="">
							<a href="https://www.storck.com/de/" target="_blank" rel="noreferrer" className="">
								<img
									loading="lazy"
									src={process.env.PUBLIC_URL + "/images/nav/storck-2x.png"}
									alt="Storck"
									width="286"
									className="mx-auto md:mx-0 mb-4 md:mb-0"
								/>
							</a>
						</div>
						<div className="text-center ">
							<a
								href="https://www.pinterest.de/sagsmitmerci/"
								target="_blank"
								rel="noreferrer"
								className="inline-block mt-2"
							>
								<svg
									className="facebook-logo"
									width={34}
									height={34}
									viewBox="0 0 34 34"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M14.1364 10C11.8558 10 10 11.8558 10 14.1364V19.8636C10 22.1442 11.8558 24 14.1364 24H19.8636C22.1442 24 24 22.1442 24 19.8636V14.1364C24 11.8558 22.1442 10 19.8636 10H14.1364ZM14.1364 10.6364H19.8636C21.8002 10.6364 23.3636 12.1998 23.3636 14.1364V19.8636C23.3636 21.8002 21.8002 23.3636 19.8636 23.3636H14.1364C12.1998 23.3636 10.6364 21.8002 10.6364 19.8636V14.1364C10.6364 12.1998 12.1998 10.6364 14.1364 10.6364ZM20.8182 12.5455C20.6494 12.5455 20.4875 12.6125 20.3682 12.7318C20.2489 12.8512 20.1818 13.013 20.1818 13.1818C20.1818 13.3506 20.2489 13.5125 20.3682 13.6318C20.4875 13.7511 20.6494 13.8182 20.8182 13.8182C20.987 13.8182 21.1488 13.7511 21.2682 13.6318C21.3875 13.5125 21.4545 13.3506 21.4545 13.1818C21.4545 13.013 21.3875 12.8512 21.2682 12.7318C21.1488 12.6125 20.987 12.5455 20.8182 12.5455ZM17 13.5C15.0708 13.5 13.5 15.0708 13.5 17C13.5 18.9292 15.0708 20.5 17 20.5C18.9292 20.5 20.5 18.9292 20.5 17C20.5 15.0708 18.9292 13.5 17 13.5ZM17 14.1364C18.5853 14.1364 19.8636 15.4147 19.8636 17C19.8636 18.5853 18.5853 19.8636 17 19.8636C15.4147 19.8636 14.1364 18.5853 14.1364 17C14.1364 15.4147 15.4147 14.1364 17 14.1364Z"
										fill="#4D7CBF"
										stroke="#4D7CBF"
										strokeWidth="0.8"
									/>
									<circle cx={17} cy={17} r={16} stroke="#4D7CBF" strokeWidth="1.5" />
								</svg>
							</a>
						</div>

						<div className="text-center md:text-right">
							<nav>
								<ul>
									{footerLinks.map((footerLink, i) => (
										<li key={i} className="inline-block p-3">
											<a href={footerLink.link} className="text-[14px] font-Br text-footerBlueDark" target={"_blank"}>
												{footerLink.text}
											</a>
										</li>
									))}
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</section>
		</footer>
	)
}

export default Footer
